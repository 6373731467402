<template>
<loader v-bind="{ loading }">
  <form>
    <columns>
      <column>
        <text-input
          classes="is-medium is-rounded"
          :error="$root.errors.company_name"
          :value="account.company_name"
          @input="updateCompanyName">
          Company Name
        </text-input>
      </column>
      <column>
        <text-input
          classes="is-medium is-rounded"
          :error="$root.errors.registration_number"
          :value="account.registration_number"
          @input="updateRegistrationNumber">
          Registration Number
        </text-input>
      </column>
      <column>
        <text-input
          classes="is-medium is-rounded"
          :error="$root.errors.vat_number"
          :value="account.vat_number"
          @input="updateVatNumber">
          VAT Number
        </text-input>
      </column>
      <column>
        <data-selector
          classes="is-medium is-rounded"
          searchable
          :value="account.industry_id"
          @input="updateIndustry"
          :items="industry_list"
          value-key="id"
          label-key="name">
          Industry
        </data-selector>
      </column>
    </columns>
    <columns>
      <column>
        <physical-address module="account" :address="account.address" />
      </column>
    </columns>

    <columns>
      <column>
        <submit-button
          class="is-medium is-rounded"
          left-icon="save"
          left-icon-type="far"
          :working="working"
          @submit="updateAccount">
          Save
        </submit-button>
      </column>
    </columns>
  </form>
</loader>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'

export default {

  data: () => ({
    working: false,
    loading: true
  }),

  async beforeCreate() {
    await this.$store.dispatch('industry/loadList')
    this.loading = false
  },

  methods: {
    ...mapMutations('auth', [
      'updateCompanyName',
      'updateRegistrationNumber',
      'updateAddress',
      'updateIndustry',
      'updateVatNumber'
    ]),
    updateAccount() {
      this.working = true
      this.$store.dispatch('auth/updateAccount', this.location.address)
        .then(() => this.$toast.success('Saved'))
        .catch(() => this.$toast.error('Something went wrong, please try again'))
        .finally(() => this.working = false)
    }
  },

  computed: {
    ...mapGetters('auth', [
      'account'
    ]),
    ...mapGetters('location', [
      'location'
    ]),
    ...mapGetters('industry', [
      'industry_list'
    ])
  }

}
</script>